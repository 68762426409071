import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/5u2TVJPaACI"
    bibleGroupSrc="https://pdfhost.io/v/TzJpAM~wG_Bible_Group_Homework_117pdf.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="When Nothing Makes Sense - If Not Us, Who?" />
  </Layout>
)

export default SermonPost
